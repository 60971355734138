export const storageConfigs = {
  profilePics: {
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/profile-pics",
    sasToken:
      "sp=raw&st=2024-07-23T13:52:09Z&se=3000-12-11T21:52:09Z&spr=https&sv=2022-11-02&sr=c&sig=6xOF%2BHCXf4%2FbWVccMXjICcFuE659kkQCIBHwxlCLsoY%3D",
    containerName: "profile-pics",
  },
  leads: {
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/imported-leads",
    sasToken:
      "sp=raw&st=2024-07-23T13:48:07Z&se=3000-05-03T21:48:07Z&spr=https&sv=2022-11-02&sr=c&sig=MbIYZuxMU8Gk7PK4RzoDdl3QQX1b7SsRlAt3LfyjsBk%3D",
    containerName: "imported-leads",
  },
  leadsList: {
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/leads-list",
    sasToken:
      "sp=raw&st=2024-08-30T04:46:41Z&se=3000-08-22T04:46:41Z&spr=https&sv=2022-11-02&sr=c&sig=koaS957JH4JUKphbuO8i6jZwZSskKcXrUCKvOepTUec%3D",
    containerName: "leads-list",
  },
  emails: {
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/block-list",
    sasToken:
      "sp=raw&st=2024-07-23T13:45:26Z&se=3000-01-06T21:45:26Z&spr=https&sv=2022-11-02&sr=c&sig=YiUzVf%2FRMZmZUlKb%2BW3WrbkfmNR4ZrzJ%2BgJ5VQt0dsM%3D",
    containerName: "email",
  },
  domains: {
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/block-list",
    sasToken:
      "sp=raw&st=2024-07-23T13:45:26Z&se=3000-01-06T21:45:26Z&spr=https&sv=2022-11-02&sr=c&sig=YiUzVf%2FRMZmZUlKb%2BW3WrbkfmNR4ZrzJ%2BgJ5VQt0dsM%3D",
    containerName: "domain",
  },
  enrichLeads: {
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/enrich-leads",
    sasToken:
      "sp=raw&st=2024-10-16T11:12:20Z&se=3001-04-01T19:12:20Z&spr=https&sv=2022-11-02&sr=c&sig=4DQT%2BF2Ma9V%2Buu%2BqwpSgngtDVwZCGBlfIeQsChkCO0Q%3D",
    containerName: "leads",
  },
  companyEnrich:{
    storageAccountUrl:
      "https://b2brocketstorage.blob.core.windows.net/company-enrich?sp=racwdli&st=2024-12-04T11:18:44Z&se=2025-12-04T19:18:44Z&spr=https&sv=2022-11-02&sr=c&sig=qQQZh3IjvhiZvyQqsBP7dBaAKND5wqnvRYQrPPg7Ios%3D",
    sasToken :"sp=racwdli&st=2024-12-04T11:18:44Z&se=2025-12-04T19:18:44Z&spr=https&sv=2022-11-02&sr=c&sig=qQQZh3IjvhiZvyQqsBP7dBaAKND5wqnvRYQrPPg7Ios%3D",
    containerName: "company-enrich",
  }
};
