import { useCallback, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import {
  Box,
  Divider,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useUpdateEffect } from "src/hooks/use-update-effect";
import queryString from "query-string";

const campaignTabs = [
  {
    label: "Leads",
    value: "leads",
  },
];

const allTabs = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Leads",
    value: "unSentEmails",
  },
  {
    label: "Sent",
    value: "emailsSent",
  },
  {
    label: "Opened",
    value: "views",
  },
  {
    label: "Clicks",
    value: "clicks",
  },
  {
    label: "Page Views",
    value: "pageViewAt",
  },
  {
    label: "Replies",
    value: "replies",
  },
  {
    label: "Video Views",
    value: "videoViewed",
  },
  {
    label: "Website Clicks",
    value: "isWebSiteVisit",
  },
  {
    label: "Conversations",
    value: "conversations",
  },
  // {
  //   label: "Video Views",
  //   value: "videoViews",
  // },
  // {
  //   label: "Replies",
  //   value: "replies",
  // },
  {
    label: "Meetings",
    value: "meetings",
  },
  {
    label: "Unsubscribe",
    value: "unsubscribe",
  },
  {
    label: "Bounced",
    value: "bounced",
  },
  {
    label: "Email Blocklist",
    value: "emailBlocklist",
  },
  {
    label: "Domain Blocklist",
    value: "domainBlocklist",
  },
];

const sortOptions = [
  {
    label: "Last update (newest)",
    value: "updatedAt|desc",
  },
  {
    label: "Last update (oldest)",
    value: "updatedAt|asc",
  },
  {
    label: "Total orders (highest)",
    value: "totalOrders|desc",
  },
  {
    label: "Total orders (lowest)",
    value: "totalOrders|asc",
  },
];

export const CustomerListSearch = (props) => {
  const {
    onFiltersChange,
    onAIFiltersChange,
    onSortChange,
    // sortBy,
    // sortDir,
    campaignView,
    selectedTab,
    setTab,
    setSearchQuery,
  } = props;
  const tabs = campaignView ? campaignTabs : allTabs;
  const queryRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(campaignView ? "leads" : "all");
  const [filters, setFilters] = useState({});

  useEffect(() => {
    // console.log(window.location.search);
    if (selectedTab !== "") {
      handleQueryTabRequest(selectedTab);
    } else {
      const parsed = queryString.parse(window.location.search);
      // console.log(parsed.tab);
      handleQueryTabRequest(parsed.tab);
    }
  }, [selectedTab]);

  const handleQueryTabRequest = (value) => {
    console.log(value, "buigugvuyg");
    if (value === "clicks") {
      handleTabsChange("clicks");
    } else if (value === "conversations") {
      handleTabsChange("conversations");
    } else if (value === "meetings") {
      handleTabsChange("meetings");
    } else if (value === "replies") {
      handleTabsChange("replies");
    } else if (value === "pageViewAt") {
      handleTabsChange("pageViewAt");
    } else if (value === "isWebSiteVisit") {
      handleTabsChange("isWebSiteVisit");
    } else {
      handleTabsChange(campaignView ? "leads" : value ? value : "all");
    }
  };

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleTabChangeFromTabs = (event, value) => {
    console.log(value, "hjgvvfvvvgv");
    handleTabsChange(value);
    setTab(value);
    setSearchQuery("");
  };

  const handleTabsChange = useCallback((value) => {
    // console.log(value);
    onAIFiltersChange?.(value);
    setCurrentTab(value);
    setFilters((prevState) => {
      const updatedFilters = {
        ...prevState,
        hasAcceptedMarketing: undefined,
        isProspect: undefined,
        isReturning: undefined,
      };

      if (value === "clicks") {
        updatedFilters["clicks"] = true;
        updatedFilters["conversations"] = false;
        updatedFilters["meetings"] = false;
        updatedFilters["unsubscribe"] = false;
        updatedFilters["bounce"] = false;
      } else if (value === "conversations") {
        updatedFilters["clicks"] = false;
        updatedFilters["conversations"] = true;
        updatedFilters["meetings"] = false;
        updatedFilters["unsubscribe"] = false;
        updatedFilters["bounce"] = false;
      } else if (value === "meetings") {
        updatedFilters["clicks"] = false;
        updatedFilters["conversations"] = false;
        updatedFilters["meetings"] = true;
        updatedFilters["unsubscribe"] = false;
        updatedFilters["bounce"] = false;
      } else if (value === "unsubscribe") {
        updatedFilters["clicks"] = false;
        updatedFilters["conversations"] = false;
        updatedFilters["meetings"] = false;
        updatedFilters["unsubscribe"] = true;
        updatedFilters["bounce"] = false;
      } 
      else if (value === "bounce") {
        updatedFilters["clicks"] = false;
        updatedFilters["conversations"] = false;
        updatedFilters["meetings"] = false;
        updatedFilters["bounce"] = true;
        updatedFilters["unsubscribe"] = false;
      }
      else {
        // "All"
        updatedFilters["clicks"] = false;
        updatedFilters["conversations"] = false;
        updatedFilters["meetings"] = false;
        updatedFilters["unsubscribe"] = false;
        updatedFilters["bounce"] = false;
      }
      return updatedFilters;
    });
  }, []);

  const handleQueryChange = useCallback((event) => {
    event.preventDefault();
    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  }, []);

  const handleSortChange = useCallback(
    (event) => {
      const [sortBy, sortDir] = event.target.value.split("|");

      onSortChange?.({
        sortBy,
        sortDir,
      });
    },
    [onSortChange]
  );

  return (
    <>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabChangeFromTabs}
        scrollButtons="auto"
        // sx={{ px: 3 }}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {(campaignView ? campaignTabs : tabs).map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      {/* <Divider />
      <Stack
        alignItems='center'
        direction='row'
        flexWrap='wrap'
        spacing={3}
        sx={{ p: 3 }}
      >
        <Box component='form' onSubmit={handleQueryChange} sx={{ flexGrow: 1 }}>
          <OutlinedInput
            defaultValue=''
            fullWidth
            inputProps={{ ref: queryRef }}
            placeholder='Search customers'
            startAdornment={
              <InputAdornment position='start'>
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            }
          />
        </Box>
        <TextField
          label='Sort By'
          name='sort'
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={`${sortBy}|${sortDir}`}
        >
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Stack> */}
    </>
  );
};

CustomerListSearch.propTypes = {
  onFiltersChange: PropTypes.func,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.oneOf(["asc", "desc"]),
};
